.preview-root {
    background-color: #B2DAB3;
    color: white;
    padding: 50px 0;

    .preview-title {
        padding-bottom: 25px;
    }

    .preview-img {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
}